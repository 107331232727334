import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { getYoutubeId, nl2br } from '../../utils/functions';
import { ProductPageProps } from './models';

import './ProductPage.scss';

const Product: FC<{ data: ProductPageProps }> = ({
  data: {
    product: {
      title,
      banner,
      bannerMobile,
      bannerText,
      formulaTitle,
      formula,
      preparationTitle,
      preparation,
      video,
      nutrimentalTitle,
      nutrimental,
      nutrimentalMobile,
      ingredientsTitle,
      ingredients,
      cierre,
      headband,
      seo,
      urls,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      {urls ? <Seo {...{ urls, seo }} /> : null}
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="product-page">
        <div className="product-page__banner">
          <Image imageData={banner} className="d-none d-md-block " alt={banner.name} />
          <Image imageData={bannerMobile} className="d-md-none" alt={bannerMobile.name} />
          <div className="product-page__banner-text">
            <div className="row justify-content-end align-items-center">
              <div className="col-md-11 text-right">
                <DangerouslySetInnerHtml html={nl2br(bannerText)} element="span" />
              </div>
            </div>
          </div>
        </div>
        <div className="product-page__specs">
          <DangerouslySetInnerHtml
            className="specs__title text-center mt-5 mb-5"
            html={nl2br(formulaTitle)}
            element="h2"
          />
          <DangerouslySetInnerHtml html={nl2br(formula)} element="span" />
        </div>

        <div className="product-page__preparation">
          <h2 className="preparation__title">{preparationTitle}</h2>
          <DangerouslySetInnerHtml html={nl2br(preparation)} element="span" />
        </div>
        {video ? (
          <div className="product-page__video px-2">
            <div className="product-page__video-wrapper">
              <YouTubeLazyLoaded videoId={getYoutubeId(video)} videoName={title} />
            </div>
          </div>
        ) : null}
        <div className="product-page__nutritional">
          <div className="container-fluid">
            <div className="row">
              <div className="col text-center text-md-start">
                <h2>{nutrimentalTitle}</h2>
              </div>
            </div>
            <Image imageData={nutrimental} className="d-md-block" alt={nutrimental.name} />
            <Image
              imageData={nutrimentalMobile}
              className="d-md-none"
              alt={nutrimentalMobile.name}
            />
          </div>
        </div>
        <div className="product-page__nutritional">
          <div className="container-fluid">
            <div className="row">
              <div className="col text-center text-md-start">
                <h2>{ingredientsTitle}</h2>
              </div>
              <DangerouslySetInnerHtml html={nl2br(ingredients)} element="span" />
            </div>
          </div>
        </div>
        <div className="product-page__closing">
          <div className="container-fluid">
            <div className="row">
              <DangerouslySetInnerHtml html={nl2br(cierre)} element="span" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    product {
      title
      bannerText
      formulaTitle
      formula
      preparationTitle
      preparation
      video
      nutrimentalTitle
      ingredientsTitle
      ingredients
      cierre
      url
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      banner {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
      bannerMobile {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
      nutrimental {
        name
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      nutrimentalMobile {
        name
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`;

export default Product;
